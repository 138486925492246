import { Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import Navbar from '../../../components/Navbar'
import { completeQuiz, getQuestions } from '../../../api'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

const Quiz = () => {
    const [questions, setQuestions] = useState([]);
    const [qapairs, setqapairs] = useState({})
    const navigate = useNavigate()
    const getData = async () => {
        try {
            const { data } = await getQuestions();
            if (data?.success) {
                setQuestions(data?.data || [])
            }
        } catch (err) {
            if (err?.response?.data?.message) {
                toast.error(err?.response?.data?.message || "You Have already completed quiz");
            }
            navigate('/task')
        }
    }
    useEffect(() => {
        getData();
    }, [])
    const handleCheck = (id, option) => {
        setqapairs(prev => { return { ...prev, [id]: { isSelect: true, option } } })
    }

    const isSeletable = (id, option, ans) => {
        if (qapairs[id]?.isSelect) {
            if (option == ans) {
                return true
            }
            if (qapairs[id].option == option) {
                if (option == ans) {
                    return 'true'
                } else {
                    return 'wrong'
                }
            }
        } else {
            return;
        }
    }

    const handleSubmit = async () => {
        try {
            const { data } = await completeQuiz();
            if (data.success) {
                toast.success("Task Compelted Successfully")
            }
            navigate('/task')
        } catch (err) {
            if (err?.response?.data?.message) {
                toast.error(err?.response?.data?.message || "You Have already completed quiz");
            }
            navigate('/task')
        }
    }

    useEffect(() => {
        if (Object.keys(qapairs).length == questions.length && questions.length > 1) {
            handleSubmit();
        }
    }, [qapairs])
    return (
        <>
            <Navbar />
            <section className="mrg_t">
                <div className="container">
                    <div className="row d-flex justify-content-center align-items-center p-0">
                        {
                            questions?.map((item) => {
                                return (
                                    <>
                                        <div className="col-md-12 col-lg-11 col-xl-10 wow flipInY mt-3 " data-wow-delay="1s">
                                            <div className="QUIZbg">
                                                <div className="QuizHeading fs-theme">{item?.question}</div>
                                                <div className={`quizOption ${isSeletable(item?._id, "one", item?.answer)}`} onClick={e => handleCheck(item._id, 'one')}><span className="pe-2">A.</span> {item?.one}</div>
                                                <div className={`quizOption ${isSeletable(item?._id, "two", item?.answer)}`} onClick={e => handleCheck(item._id, 'two')}><span className="pe-2">B.</span> {item?.two}</div>
                                                <div className={`quizOption ${isSeletable(item?._id, "three", item?.answer)}`} onClick={e => handleCheck(item._id, 'three')}><span className="pe-2">C.</span> {item?.three}</div>
                                                <div className={`quizOption ${isSeletable(item?._id, "four", item?.answer)}`} onClick={e => handleCheck(item._id, 'four')}><span className="pe-2">D.</span> {item?.four}</div>
                                            </div>
                                        </div>
                                    </>
                                )
                            })
                        }
                        {/* <div className="col-md-12 col-lg-11 col-xl-10 wow flipInY mt-3 " data-wow-delay="1s">
                            <div className="QUIZbg">
                                <div className="QuizHeading fs-theme">Lorem Ipsum is simply dummy text of the printing and typesetting industry
                                    It was popularised in the 1960s?</div>

                                <div className="quizOption"><span className="pe-2">A.</span> Lorem Ipsum is simply dummy</div>
                                <div className="quizOption true"><span className="pe-2">B.</span> Lorem Ipsum is simply dummy</div>
                                <div className="quizOption wrong"><span className="pe-2">C.</span> Lorem Ipsum is simply dummy</div>
                                <div className="quizOption"><span className="pe-2">D.</span> Lorem Ipsum is simply dummy</div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>
        </>
    )
}

export default Quiz