import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import api, { getUserPoints } from '../api';
import { setPoints, setUser } from '../store/Slices/userSlice';

const UpdateComponent = () => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user)
    const getPoints = async () => {
        try {
            // const { data } = await getUserPoints();
            // if (data.success) {
            //     // dispatch(setPoints(data.points))
            // }
        } catch (err) { }
    }
    api.interceptors.response.use(
        response => {
            if (response.config.url !== "/getpoints" && response.config.url !== "/register") {
                getPoints();
            }
            return response;
        },
        error => {
            return Promise.reject(error);
        }
    );
    return (
        <>
        </>
    )
}

export default UpdateComponent