import React from 'react'

const Discord = () => {
  return (
    <section>
    <div className="bgFixed">

        <div className="d-flex justify-content-center align-items-center flex-column pad_tb position-relative">
            <img src="/images/Zk_Realm_Tree.png" alt="Tree image for decorate in Zk Realm website" className="img-fluid zkTree d-none d-md-block" draggable="false" />
            <img src="/images/Horror_Trees.png" alt="Horrore_Trees image for decorate in Zk Realm website" className="img-fluid zkHorrorTree" draggable="false" />
            {/* <!-- ============  Heading  ============ --> */}
            <div className="heading d-flex justify-content-center align-items-center wow flash pb-4 cusZindex mrgBzero">
                <h2>JOIN OUR DISCORD</h2>
            </div>

            <a href="#" className="cusZindex wow bounceIn"><img src="/images/Discord_Btn.png" alt="Join Button For ZK Realm Discord" className="img-fluid disBtn" draggable="false" /></a>

        </div>
    
    </div>
</section>
  )
}

export default Discord