import React from 'react'
import Navbar from '../components/Navbar'
import HeroSection from '../components/home/HeroSection'
import AboutUs from '../components/home/AboutUs'
import Discord from '../components/home/Discord'
import Roadmap from '../components/home/Roadmap'
import Team from '../components/home/Team'
import FaQ from '../components/home/FaQ'
import Footer from '../components/Footer'

const Home = () => {
  return (
    
    <>
    <Navbar></Navbar>
    <HeroSection></HeroSection>
    <AboutUs></AboutUs>
    <Discord></Discord>
    <Roadmap></Roadmap>
    <Team></Team>
    <FaQ></FaQ>
    <Footer></Footer>
    </>
  )
}

export default Home