import React, { useEffect, useState } from 'react'
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'
import { getAllTasks } from '../../api'
import TaskCard from './components/TaskCard'
import { useSelector } from 'react-redux'
import ConnectWallet from '../../components/ConnectWallet'
import SpinTask from './components/SpinTask'
import Checkin from './components/Checkin'
import Quiz from './components/Quiz'
import QuizTask from './components/QuizTask'

const Task = () => {
    const [tasks, setTasks] = useState([])
    const [fetch, setFetch] = useState(5)
    const { user } = useSelector(state => state.user)
    useEffect(() => {
        (async () => {
            try {
                const { data } = await getAllTasks()
                setTasks(data?.data)
            } catch (err) {
            }
        })()
    }, [fetch, user])
    return (
        <>
            <Navbar />
            <section className="mrg_t">
                <div className="container">
                    <div className="row d-flex justify-content-center align-items-center">
                        <div className="col-md-12 col-lg-11 col-xl-10 py-4 wow flipInY" data-wow-delay="1s">
                            {
                                user.walletAddress ?
                                    <div className="referGlass">
                                        <div className="row p-3 p-md-5 w-100 m-0">

                                            <div className="col-12 minusMrg">
                                                <img src="/images/ReferAndEarn_Image.png" className="img-fluid"
                                                    alt="Refer And Earn Page Image" draggable="false" />
                                            </div>

                                            <div className="col-12 d-flex flex-column justify-content-around align-items-center pb-4">
                                                <div
                                                    className="w-100 d-flex flex-column flex-lg-row justify-content-center align-items-center">
                                                    <h1 className="pointData ms-lg-4 text-center">Task</h1>
                                                </div>
                                            </div>

                                            {
                                                tasks.map(item => {
                                                    return <TaskCard data={item} setTasks={setTasks} setFetch={setFetch} />
                                                })
                                            }
                                            <SpinTask></SpinTask>
                                            <Checkin></Checkin>
                                            <QuizTask></QuizTask>
                                        </div>
                                    </div>
                                    :
                                    <div className="d-flex flex-column justify-content-center align-items-center">
                                        <h2 className='mb-5'>You Need To Connect Wallet to Complete the tasks</h2>
                                        <ConnectWallet />
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default Task