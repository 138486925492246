import { Modal } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { applyTask, verifyTask } from '../../../api'
import { ContractContext } from '../../../context/ContractContext'

const TaskModal = ({ data, isOpen, setIsOpen, setFetch,id }) => {
    const [inputVal, setInputVal] = useState("")
    const [loading, setLoading] = useState(true)
    const { setFetchUser } = useContext(ContractContext);

    const verifyTaskFunc = async () => {
        try {
            if (!inputVal) {
                toast.error("Please Fill This Detail")
            }
            setLoading(true)
            const { data } = await verifyTask({ id, userDetails: inputVal });
            if (data.success) {
                toast.success("Task Completed Successfully");
                setFetch(prev => prev + 5)
                setFetchUser(prev => prev + 5)
                setIsOpen(false)
            }
        } catch (err) { toast.error(err?.response?.data?.message || err.message) }
    }
    useEffect(() => {
        if (!isOpen) {
            setInputVal("")
        }
    }, [isOpen])

    return (
        <Modal title={"Complete Task"} open={isOpen} onOk={verifyTaskFunc} onClose={e => setIsOpen(false)} onCancel={e => setIsOpen(false)}>
            <input placeholder={data?.task?.message || "Please Enter Details"} className='cusInput w-100 my-2 text-white' value={inputVal} onChange={e => setInputVal(e.target.value)} />
        </Modal>
    )
}

export default TaskModal