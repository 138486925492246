import React from 'react'

const AboutUs = () => {
  return (
    <section id="AboutUs">
        <div className="container mrg_b">
            <div className="row wow flash">
                <div className="heading d-flex justify-content-center align-items-center mrg_tb">
                    <div className="right_line">
                        <div className="Line200 RGreen"></div>
                        <div className="Line100 ROrg ms-auto"></div>
                    </div>
                    <h2>ABOUT US</h2>
                    <div className="left_line">
                        <div className="Line200 LGreen"></div>
                        <div className="Line100 LOrg"></div>
                    </div>
                </div>
            </div>

            <div className="row justify-content-center align-items-center">
                <div className="col-12 col-lg-6 cusZindex wow slideInDown">
                    <img src="/images/aboutUsImg.png" alt="About Of ZK Realm" draggable="false" className="img-fluid" />
                </div>
                <div className="col-12 col-lg-6 cusZindex wow fadeInLeft" data-wow-delay="1s">
                    <div className="cl-t lh-theme pb-5">
                        Welcome to <span className="cl-M">ZK Realm</span>, a pioneering NFT project on the
                        ZKSync blockchain. With a total supply of 8888 NFTs, our
                        realm blends artistry and innovation seamlessly. At ZK
                        Realm, authenticity reigns supreme - no empty promises,
                        just genuine creativity. As we embark on this journey, we
                        extend a hand to all, offering free minting and whitelist
                        opportunities. Join us in shaping the future of art and
                        technology, where every NFT holds the potential to
                        captivate and inspire.
                    </div>
                    <a href="#"><div className="cusBtnTwo">Read More</div></a>
                </div>
            </div>

        </div>
    </section>
  )
}

export default AboutUs