import React from 'react'
import Navbar from '../components/Navbar'
import Spinner from '../components/spin/Spinner'

const Spin = () => {
  return (
    <>
    <Navbar></Navbar>
    <Spinner></Spinner>
    </>
  )
}

export default Spin