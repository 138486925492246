import React, { createContext, useState } from 'react';

const ContractContext = createContext();

const ContractProvider = ({ children }) => {
  const [contract, setContract] = useState(null);
  const [address, setAddress] = useState("");
  const [fetchUser, setFetchUser] = useState(1)

  return (
    <ContractContext.Provider value={{ contract, setContract, setAddress, address, setFetchUser, fetchUser }}>
      {children}
    </ContractContext.Provider>
  );
};

export { ContractContext, ContractProvider };
